.BlockSpinner {
  position: relative;
  width: 89px;
  height: 89px;
}
.BlockSpinner::before {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
  content: "";
  box-shadow: 32px 0 0 rgba(66, 153, 225, 0), 64px 0 0 rgba(66, 153, 225, 0),
    0 -32px 0 rgba(66, 153, 225, 0), 32px -32px 0 rgba(66, 153, 225, 0),
    64px -32px 0 rgba(66, 153, 225, 0), 0 -64px rgba(66, 153, 225, 0),
    32px -64px rgba(66, 153, 225, 0), 64px -64px rgba(197, 48, 48, 0);
  animation: loader 1.5s linear forwards infinite;
}
.BlockSpinner::after {
  position: absolute;
  bottom: 10px;
  left: 0;
  display: block;
  width: 25px;
  height: 25px;
  background-color: #BEE3F8;
  opacity: 0;
  content: "";
  animation: base 1.5s linear forwards infinite;
}
@keyframes base {
  0% {
    bottom: 10px;
    opacity: 0;
  }
  5%,
  50% {
    bottom: 0;
    opacity: 1;
  }
  55%,
  100% {
    bottom: -10px;
    opacity: 0;
  }
}
@keyframes loader {
  0% {
    box-shadow: 32px -10px rgba(66, 153, 225, 0), 64px 0 rgba(66, 153, 225, 0),
      0 -32px rgba(66, 153, 225, 0), 32px -32px rgba(66, 153, 225, 0),
      64px -32px rgba(66, 153, 225, 0), 0 -64px rgba(66, 153, 225, 0),
      32px -64px rgba(66, 153, 225, 0), 64px -64px rgba(197, 48, 48, 0);
  }
  5% {
    box-shadow: 32px -10px rgba(66, 153, 225, 0), 64px 0 rgba(66, 153, 225, 0),
      0 -32px rgba(66, 153, 225, 0), 32px -32px rgba(66, 153, 225, 0),
      64px -32px rgba(66, 153, 225, 0), 0 -64px rgba(66, 153, 225, 0),
      32px -64px rgba(66, 153, 225, 0), 64px -64px rgba(197, 48, 48, 0);
  }
  10% {
    box-shadow: 32px 0 rgba(66, 153, 225, 1), 64px -10px rgba(66, 153, 225, 0),
      0 -32px rgba(66, 153, 225, 0), 32px -32px rgba(66, 153, 225, 0),
      64px -32px rgba(66, 153, 225, 0), 0 -64px rgba(66, 153, 225, 0),
      32px -64px rgba(66, 153, 225, 0), 64px -64px rgba(197, 48, 48, 0);
  }
  15% {
    box-shadow: 32px 0 rgba(66, 153, 225, 1), 64px 0 rgba(66, 153, 225, 1),
      0 -42px rgba(66, 153, 225, 0), 32px -32px rgba(66, 153, 225, 0),
      64px -32px rgba(66, 153, 225, 0), 0 -64px rgba(66, 153, 225, 0),
      32px -64px rgba(66, 153, 225, 0), 64px -64px rgba(197, 48, 48, 0);
  }
  20% {
    box-shadow: 32px 0 rgba(66, 153, 225, 1), 64px 0 rgba(66, 153, 225, 1),
      0 -32px rgba(66, 153, 225, 1), 32px -42px rgba(66, 153, 225, 0),
      64px -32px rgba(66, 153, 225, 0), 0 -64px rgba(66, 153, 225, 0),
      32px -64px rgba(66, 153, 225, 0), 64px -64px rgba(197, 48, 48, 0);
  }
  25% {
    box-shadow: 32px 0 rgba(66, 153, 225, 1), 64px 0 rgba(66, 153, 225, 1),
      0 -32px rgba(66, 153, 225, 1), 32px -32px rgba(66, 153, 225, 1),
      64px -42px rgba(66, 153, 225, 0), 0 -64px rgba(66, 153, 225, 0),
      32px -64px rgba(66, 153, 225, 0), 64px -64px rgba(197, 48, 48, 0);
  }
  30% {
    box-shadow: 32px 0 rgba(66, 153, 225, 1), 64px 0 rgba(66, 153, 225, 1),
      0 -32px rgba(66, 153, 225, 1), 32px -32px rgba(66, 153, 225, 1),
      64px -32px rgba(66, 153, 225, 1), 0 -74px rgba(66, 153, 225, 0),
      32px -64px rgba(66, 153, 225, 0), 64px -64px rgba(197, 48, 48, 0);
  }
  35% {
    box-shadow: 32px 0 rgba(66, 153, 225, 1), 64px 0 rgba(66, 153, 225, 1),
      0 -32px rgba(66, 153, 225, 1), 32px -32px rgba(66, 153, 225, 1),
      64px -32px rgba(66, 153, 225, 1), 0 -64px rgba(66, 153, 225, 1),
      32px -74px rgba(66, 153, 225, 0), 64px -64px rgba(197, 48, 48, 0);
  }
  40% {
    box-shadow: 32px 0 rgba(66, 153, 225, 1), 64px 0 rgba(66, 153, 225, 1),
      0 -32px rgba(66, 153, 225, 1), 32px -32px rgba(66, 153, 225, 1),
      64px -32px rgba(66, 153, 225, 1), 0 -64px rgba(66, 153, 225, 1),
      32px -64px rgba(66, 153, 225, 1), 64px -74px rgba(197, 48, 48, 0);
  }
  45%,
  55% {
    box-shadow: 32px 0 rgba(66, 153, 225, 1), 64px 0 rgba(66, 153, 225, 1),
      0 -32px rgba(66, 153, 225, 1), 32px -32px rgba(66, 153, 225, 1),
      64px -32px rgba(66, 153, 225, 1), 0 -64px rgba(66, 153, 225, 1),
      32px -64px rgba(66, 153, 225, 1), 64px -64px rgba(197, 48, 48, 1);
  }
  60% {
    box-shadow: 32px 10px rgba(66, 153, 225, 0), 64px 0 rgba(66, 153, 225, 1),
      0 -32px rgba(66, 153, 225, 1), 32px -32px rgba(66, 153, 225, 1),
      64px -32px rgba(66, 153, 225, 1), 0 -64px rgba(66, 153, 225, 1),
      32px -64px rgba(66, 153, 225, 1), 64px -64px rgba(197, 48, 48, 1);
  }
  65% {
    box-shadow: 32px 10px rgba(66, 153, 225, 0), 64px 10px rgba(66, 153, 225, 0),
      0 -32px rgba(66, 153, 225, 1), 32px -32px rgba(66, 153, 225, 1),
      64px -32px rgba(66, 153, 225, 1), 0 -64px rgba(66, 153, 225, 1),
      32px -64px rgba(66, 153, 225, 1), 64px -64px rgba(197, 48, 48, 1);
  }
  70% {
    box-shadow: 32px 10px rgba(66, 153, 225, 0), 64px 10px rgba(66, 153, 225, 0),
      0 -22px rgba(66, 153, 225, 0), 32px -32px rgba(66, 153, 225, 1),
      64px -32px rgba(66, 153, 225, 1), 0 -64px rgba(66, 153, 225, 1),
      32px -64px rgba(66, 153, 225, 1), 64px -64px rgba(197, 48, 48, 1);
  }
  75% {
    box-shadow: 32px 10px rgba(66, 153, 225, 0), 64px 10px rgba(66, 153, 225, 0),
      0 -22px rgba(66, 153, 225, 0), 32px -22px rgba(66, 153, 225, 0),
      64px -32px rgba(66, 153, 225, 1), 0 -64px rgba(66, 153, 225, 1),
      32px -64px rgba(66, 153, 225, 1), 64px -64px rgba(197, 48, 48, 1);
  }
  80% {
    box-shadow: 32px 10px rgba(66, 153, 225, 0), 64px 10px rgba(66, 153, 225, 0),
      0 -22px rgba(66, 153, 225, 0), 32px -22px rgba(66, 153, 225, 0),
      64px -22px rgba(66, 153, 225, 0), 0 -64px rgba(66, 153, 225, 1),
      32px -64px rgba(66, 153, 225, 1), 64px -64px rgba(197, 48, 48, 1);
  }
  85% {
    box-shadow: 32px 10px rgba(66, 153, 225, 0), 64px 10px rgba(66, 153, 225, 0),
      0 -22px rgba(66, 153, 225, 0), 32px -22px rgba(66, 153, 225, 0),
      64px -22px rgba(66, 153, 225, 0), 0 -54px rgba(66, 153, 225, 0),
      32px -64px rgba(66, 153, 225, 1), 64px -64px rgba(197, 48, 48, 1);
  }
  90% {
    box-shadow: 32px 10px rgba(66, 153, 225, 0), 64px 10px rgba(66, 153, 225, 0),
      0 -22px rgba(66, 153, 225, 0), 32px -22px rgba(66, 153, 225, 0),
      64px -22px rgba(66, 153, 225, 0), 0 -54px rgba(66, 153, 225, 0),
      32px -54px rgba(66, 153, 225, 0), 64px -64px rgba(197, 48, 48, 1);
  }
  95%,
  100% {
    box-shadow: 32px 10px rgba(66, 153, 225, 0), 64px 10px rgba(66, 153, 225, 0),
      0 -22px rgba(66, 153, 225, 0), 32px -22px rgba(66, 153, 225, 0),
      64px -22px rgba(66, 153, 225, 0), 0 -54px rgba(66, 153, 225, 0),
      32px -54px rgba(66, 153, 225, 0), 64px -54px rgba(197, 48, 48, 0);
  }
}
