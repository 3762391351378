@media print {
  * {
    visibility: hidden;
    margin: 0;
    padding: 0;
  }

  #print-target {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw !important;
    overflow: visible;
  }
  #print-target * {
    visibility: visible;
  }
}
