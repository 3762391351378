/* .TileButton__container {
  transition: all 200ms;
  border-width: 3px;
} */

.TileButton {
  width: 176px;
  transition: all 200ms;
}

/* .TileButton__container:hover {
  transform: translateY(-8px);
} */
.TileButton:hover {
  transform: translateY(-8px);
}

.TileButton .Icon--primary {
  transition: color 200ms;
  /* NB: text-gray-900 */
  color: #1a202c;
}

.TileButton .Icon--secondary {
  transition: color 200ms;
  /* NB: text-gray-500 */
  color: #a0aec0;
}

.TileButton:hover .Icon--primary {
  /* NB: text-blue-900 */
  color: #2a4365;
}

.TileButton:hover .Icon--secondary {
  /* NB: text-blue-500 */
  color: #4299e1;
}
